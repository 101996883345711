<template>
  <div class="register">
    <Heard />
    <!-- <Tip /> -->
    <div class="resbox container">
      <div class="form">
        <h2>注册</h2>
        <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
          label-position="top"
        >
          <el-form-item label="注册手机号" prop="mobile">
            <el-input
              v-model.number="ruleForm.mobile"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="pass">
            <el-input
              type="password"
              v-model="ruleForm.pass"
              autocomplete="off"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="checkPass">
            <el-input
              type="password"
              v-model="ruleForm.checkPass"
              autocomplete="off"
              placeholder="确保密码输入正确"
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="age">
            <el-input
              v-model.number="ruleForm.age"
              placeholder="请填写正确的验证码"
            ></el-input>
          </el-form-item>
          <!-- <p class="sendiscode">发送验证码</p> -->

          <span class="sendiscode" v-show="show" @click="getCode"
            >发送验证码</span
          >
          <span v-show="!show" class="count sendiscode">{{ count }} 秒</span>

          <p><i class="el-icon-success"></i> 《职业云联会员注册协议》</p>

          <el-form-item>
            <el-button class="subbtn" type="one" @click="submitForm('ruleForm')"
              >同意协议并注册</el-button
            >
          </el-form-item>
          <router-link to="/login" class="footer-tip"
            >已有账号？立即登录</router-link
          >
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { api_saveUserInfo, api_sendMsg } from "@/apis/apis";
import Heard from "@/components/Heard.vue";
// import Tip from "@/components/Tip.vue";
export default {
  components: {
    Heard,
    // Tip,
  },
  data() {
    //验证码
    var checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("验证码不能为空"));
      } else if (value != this.msgcode) {
        return callback(new Error("验证码错误"));
      }

      callback();
    };
    /* 密码 */
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    /* 手机号 */
    var testmobile = (rule, value, callback) => {
      var re = /^1\d{10}$/;
      if (value === "") {
        callback(new Error("请输入手机号码"));
      } else {
        if (!re.test(value)) {
          callback(new Error("请输入正确的手机号"));
        }
        callback();
      }
    };
    /* 再次输入 */
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        mobile: "",
        pass: "",
        checkPass: "",
        age: "",
      },
      msgcode: "",
      show: true,
      count: 1,
      rules: {
        mobile: [{ validator: testmobile, trigger: "blur" }],
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
        age: [{ validator: checkAge, trigger: "blur" }],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          api_saveUserInfo({
            mobile: this.ruleForm.mobile,
            password: this.ruleForm.checkPass,
            msgCode: this.ruleForm.age,
          }).then((res) => {
            console.log(res, "111");

            if (res.data.code == 200) {
              this.$message({
                showClose: true,
                message: "密码设置成功，请重新登录",
                type: "success",
              });

              setTimeout(() => {
                this.$router.push("/login");
              }, 2000);
            } else {
              alert(res.data.data);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    //发送验证码

    getCode() {
      if (this.ruleForm.mobile != "") {
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
        api_sendMsg({
          mobile: this.ruleForm.mobile,
          msgType: 1,
        }).then((res) => {
          console.log(res);
          this.msgcode = res.data.data.code;
        });
      } else {
        this.$message({
          showClose: true,
          message: "请输入手机号",
          type: "error",
          offset: 60,
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.register {
  min-width: 1200px;
  .resbox {
    height: 720px;
    background: #ffffff;
    border: 1px solid #f2f2f2;
    opacity: 1;
    border-radius: 0px;
    display: flex;
    justify-content: center;

    .form {
      width: 310px;

      h2 {
        text-align: center;
        margin: 20px 0;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
      }
      .el-form {
        position: relative;
        display: flex;
        flex-direction: column;
        p {
          margin-bottom: 10px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          opacity: 1;
          cursor: pointer;
        }
        .el-button--one {
          background: #2d69f9;
        }
        .subbtn {
          width: 100%;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          border-radius: 2px;
        }
        .footer-tip {
          margin: 0 auto;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #225592;
        }
        .sendiscode {
          position: absolute;
          right: 40px;
          bottom: 136px;
          font-weight: 400;

          color: #225592;
          font-size: 14px;
        }
      }
    }
  }
}
</style>